import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"

const CustomServices = () => (
  <Layout>
    <SEO title="Custom Services" />
    <HeroInner title="Custom Services" />
  </Layout>
)

export default CustomServices
