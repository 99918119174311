import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import DynamicImage from "../DynamicImage/dynamic-image"


import css from "./hero-inner.module.scss"

const HeroInner = ({ title, imagePath, imageAlt }) => {

  return (
    <div className={css.hero}>
      <div className={css.hero__overlay}></div>
      <DynamicImage alt={imageAlt} filename={imagePath} />
      <div className={css.hero__content}>
        <div className={css.hero__container}>
          <h1 className={css.hero__title}>{title}</h1>
        </div>
      </div>
    </div>
  )
}

export default HeroInner
